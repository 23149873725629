import React from "react"

const Terms = () => (
    <div style={{ maxWidth: 1024, margin: `24px auto` }}>
      <p className="c23 title">
        <span className="c18 c8">TERMS AND CONDITIONS</span>
      </p>
      <ol className="c5 lst-kix_list_2-2 start" start={1}>
        <li className="c12">
          <p style={{ display: `inline` }}>
            <span className="c3">SAAS SERVICES AND SUPPORT</span>
          </p>
        </li>
      </ol>
      <ol className="c5 lst-kix_list_2-3 start" start={1}>
        <li className="c2">
          <p style={{ display: `inline` }}>
            <span className="c4">
              THIS SUBSCRIPTION AGREEMENT GOVERNS CUSTOMER’S ACQUISITION AND USE
              OF GATSBY CLOUD SERVICES. CUSTOMER REGISTERS FOR A FREE TRIAL OF
              GATSBY CLOUD SERVICES OR FOR FREE SERVICES, THE APPLICABLE
              PROVISIONS OF THIS AGREEMENT WILL ALSO GOVERN THAT FREE TRIAL OR
              THOSE FREE SERVICES. BY ACCEPTING THIS AGREEMENT, BY (1) CLICKING
              A BOX INDICATING ACCEPTANCE, (2) EXECUTING AN ORDER FORM THAT
              REFERENCES THIS AGREEMENT, OR (3) USING FREE SERVICES, CUSTOMER
              AGREES TO THE TERMS OF THIS AGREEMENT. IF THE INDIVIDUAL ACCEPTING
              THIS AGREEMENT IS ACCEPTING ON BEHALF OF A COMPANY OR OTHER LEGAL
              ENTITY, SUCH INDIVIDUAL REPRESENTS THAT THEY HAVE THE AUTHORITY TO
              BIND SUCH ENTITY AND ITS AFFILIATES TO THESE TERMS AND CONDITIONS,
              IN WHICH CASE THE TERM “CUSTOMER” SHALL REFER TO SUCH ENTITY AND
              ITS AFFILIATES. IF THE INDIVIDUAL ACCEPTING THIS AGREEMENT DOES
              NOT HAVE SUCH AUTHORITY, OR DOES NOT AGREE WITH THESE TERMS AND
              CONDITIONS, SUCH INDIVIDUAL MUST NOT ACCEPT THIS AGREEMENT AND MAY
              NOT USE THE SERVICES.{` `}
            </span>
          </p>
        </li>
        <li className="c2">
          <p style={{ display: `inline` }}>
            <span className="c4">
              This Agreement was last updated on February 23, 2018. It is
              effective between Customer and SFDC as of the date of Customer’s
              accepting this Agreement.
            </span>
          </p>
        </li>
        <li className="c2">
          <p style={{ display: `inline` }}>
            <span className="c4">
              Subject to the terms of this Agreement, Company will use
              commercially reasonable efforts to provide Customer the Services
              in accordance with the Service Level Terms attached hereto as
              Exhibit A. As part of the registration process, Customer will
              identify an administrative user name and password for Customer’s
              Company account.
            </span>
          </p>
        </li>
      </ol>
      <p className="c13">
        <span className="c4" />
      </p>
      <p className="c17">
        <span className="c4">
          1.4 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Subject to the
          terms hereof, Company will provide Customer with reasonable technical
          support services in accordance with the terms set forth in Exhibit B.
          &nbsp;
        </span>
      </p>
      <p className="c13">
        <span className="c4" />
      </p>
      <ol className="c5 lst-kix_list_2-2" start={2}>
        <li className="c12">
          <p style={{ display: `inline` }}>
            <span className="c3">RESTRICTIONS AND RESPONSIBILITIES &nbsp;</span>
          </p>
        </li>
      </ol>
      <ol className="c5 lst-kix_list_2-3 start" start={1}>
        <li className="c2">
          <p style={{ display: `inline` }}>
            <span className="c8">
              Further, Customer may not remove or export from the United States
              or allow the export or re-export of the Services, Software or
              anything related thereto, or any direct product thereof in
              violation of any restrictions, laws or regulations of the United
              States Department of Commerce, the United States Department of
              Treasury Office of Foreign Assets Control, or any other United
              States or foreign agency or authority. &nbsp;As defined in FAR
              section 2.101, the Software and documentation are “commercial
              items” and according to DFAR section 252.2277014(a)(1) and (5) are
              deemed to be “commercial computer software” and “commercial
              computer software documentation.” &nbsp;Consistent with DFAR
              section 227.7202 and FAR section 12.212, any use modification,
              reproduction, release, performance, display, or disclosure of such
              commercial software or commercial software documentation by the
              U.S. Government will be governed solely by the terms of this
              Agreement and will be prohibited except to the extent expressly
              permitted by the terms of this Agreement.
            </span>
          </p>
        </li>
        <li className="c2">
          <p style={{ display: `inline` }}>
            <span className="c4">
              Customer represents, covenants, and warrants that Customer will
              use the Services only in compliance with Company’s standard
              published policies then in effect (the “Policy”) and all
              applicable laws and regulations. &nbsp;Customer hereby agrees to
              indemnify and hold harmless Company against any damages, losses,
              liabilities, settlements and expenses (including without
              limitation costs and attorneys’ fees) in connection with any claim
              or action that arises from an alleged violation of the foregoing
              or otherwise from Customer’s use of Services. Although Company has
              no obligation to monitor Customer’s use of the Services, Company
              may do so and may prohibit any use of the Services it believes may
              be (or alleged to be) in violation of the foregoing.
            </span>
          </p>
        </li>
        <li className="c2">
          <p style={{ display: `inline` }}>
            <span className="c4">
              Customer shall be responsible for obtaining and maintaining any
              equipment and ancillary services needed to connect to, access or
              otherwise use the Services, including, without limitation, modems,
              hardware, servers, software, operating systems, networking, web
              servers and the like (collectively, “Equipment”). &nbsp;Customer
              shall also be responsible for maintaining the security of the
              Equipment, Customer account, passwords (including but not limited
              to administrative and user passwords) and files, and for all uses
              of Customer account or the Equipment with or without Customer’s
              knowledge or consent.
            </span>
          </p>
        </li>
      </ol>
      <ol className="c5 lst-kix_list_2-2" start={3}>
        <li className="c12">
          <p style={{ display: `inline` }}>
            <span className="c3">CONFIDENTIALITY; PROPRIETARY RIGHTS</span>
          </p>
        </li>
      </ol>
      <p className="c13">
        <span className="c0" />
      </p>
      <ol className="c5 lst-kix_list_2-3" start={4}>
        <li className="c2">
          <p style={{ display: `inline` }}>
            <span className="c8">
              Each party (the “Receiving Party”) understands that the other
              party (the “Disclosing Party”) has disclosed or may disclose
              business, technical or financial information relating to the
              Disclosing Party’s business (hereinafter referred to as
              “Proprietary Information” of the Disclosing Party).
              &nbsp;Proprietary Information of Company includes non-public
              information regarding features, functionality and performance of
              the Service. &nbsp;Proprietary Information of Customer includes
              non-public data provided by Customer to Company to enable the
              provision of the Services (“Customer Data”). The Receiving Party
              agrees: (i)&nbsp;to take reasonable precautions to protect such
              Proprietary Information, and (ii)&nbsp;not to use (except in
              performance of the Services or as otherwise permitted herein) or
              divulge to any third person any such Proprietary Information.
              &nbsp;The Disclosing Party agrees that the foregoing shall not
              apply with respect to any information after five (5) years
              following the disclosure thereof or any information that the
              Receiving Party can document (a)&nbsp;is or becomes generally
              available to the public, or (b)&nbsp;was in its possession or
              known by it prior to receipt from the Disclosing Party, or
              (c)&nbsp;was rightfully disclosed to it without restriction by a
              third party, or (d)&nbsp;was independently developed without use
              of any Proprietary Information of the Disclosing Party or
              (e)&nbsp;is required to be disclosed by law. &nbsp;
            </span>
          </p>
        </li>
        <li className="c2">
          <p style={{ display: `inline` }}>
            <span className="c8">
              Customer shall own all right, title and interest in and to the
              Customer Data. Company shall own and retain all right, title and
              interest in and to (a) the Services and Software, all
              improvements, enhancements or modifications thereto, (b) any
              software, applications, inventions or other technology developed
              in connection with Implementation Services or support, and (c) all
              intellectual property rights related to any of the foregoing.
              &nbsp; &nbsp;{` `}
            </span>
          </p>
        </li>
        <li className="c2">
          <p style={{ display: `inline` }}>
            <span className="c8">
              Notwithstanding anything to the contrary, Company shall have the
              right collect and analyze data and other information relating to
              the provision, use and performance of various aspects of the
              Services and related systems and technologies (including, without
              limitation, information concerning Customer Data and data derived
              therefrom), and &nbsp;Company will be free (during and after the
              term hereof) to (i) use such information and data to improve and
              enhance the Services and for other development, diagnostic and
              corrective purposes in connection with the Services and other
              Company offerings, and (ii) disclose such data solely in aggregate
              or other de-identified form in connection with its business.
              &nbsp;No rights or licenses are granted except as expressly set
              forth herein. &nbsp;{` `}
            </span>
          </p>
        </li>
      </ol>
      <ol className="c5 lst-kix_list_2-2" start={4}>
        <li className="c12">
          <p style={{ display: `inline` }}>
            <span className="c3">PAYMENT OF FEES</span>
          </p>
        </li>
      </ol>
      <ol className="c5 lst-kix_list_2-3 start" start={1}>
        <li className="c2">
          <p style={{ display: `inline` }}>
            <span className="c4">
              Customer will pay Company applicable fees for the Services.
              &nbsp;If Customer’s use of the Services exceeds the Service
              Capacity or otherwise requires the payment of additional fees (per
              the terms of this Agreement), Customer shall be billed for such
              usage and Customer agrees to pay the additional fees in the manner
              provided herein. &nbsp;Company reserves the right to change the
              Fees or applicable charges and to institute new charges and Fees
              at the end of the Initial Service Term or then current renewal
              term, upon thirty&nbsp;(30) days prior notice to Customer (which
              may be sent by email). If Customer believes that Company has
              billed Customer incorrectly, Customer must contact Company no
              later than 60&nbsp;days after the closing date on the first
              billing statement in which the error or problem appeared, in order
              to receive an adjustment or credit. &nbsp;Inquiries should be
              directed to Company’s customer support department.
            </span>
          </p>
        </li>
        <li className="c2">
          <p style={{ display: `inline` }}>
            <span className="c4">
              Company may choose to bill through an invoice, in which case, full
              payment for invoices issued in any given month must be received by
              Company thirty&nbsp;(30) days after the mailing date of the
              invoice. &nbsp;Unpaid amounts are subject to a finance charge of
              1.5% per month on any outstanding balance, or the maximum
              permitted by law, whichever is lower, plus all expenses of
              collection and may result in immediate termination of Service.
              Customer shall be responsible for all taxes associated with
              Services other than U.S. taxes based on Company’s net income.
              &nbsp;
            </span>
          </p>
        </li>
      </ol>
      <ol className="c5 lst-kix_list_2-2" start={5}>
        <li className="c12">
          <p style={{ display: `inline` }}>
            <span className="c3">TERM AND TERMINATION</span>
          </p>
        </li>
      </ol>
      <ol className="c5 lst-kix_list_2-3 start" start={1}>
        <li className="c2">
          <p style={{ display: `inline` }}>
            <span className="c4">
              Subject to earlier termination as provided below, this Agreement
              is for monthly service, and shall be automatically renewed for
              additional periods of the same duration as the Initial Service
              Term (collectively, the “Term”), unless either party requests
              termination at least thirty (30) days prior to the end of the
              then-current term.
            </span>
          </p>
        </li>
        <li className="c2">
          <p style={{ display: `inline` }}>
            <span className="c4">
              In addition to any other remedies it may have, either party may
              also terminate this Agreement upon thirty (30) days’ notice (or
              without notice in the case of nonpayment), if the other party
              materially breaches any of the terms or conditions of this
              Agreement. &nbsp;Customer will pay in full for the Services up to
              and including the last day on which the Services are provided. All
              sections of this Agreement which by their nature should survive
              termination will survive termination, including, without
              limitation, accrued rights to payment, confidentiality
              obligations, warranty disclaimers, and limitations of liability.{` `}
            </span>
          </p>
        </li>
      </ol>
      <ol className="c5 lst-kix_list_2-2" start={6}>
        <li className="c12">
          <p style={{ display: `inline` }}>
            <span className="c3">WARRANTY AND DISCLAIMER</span>
          </p>
        </li>
      </ol>
      <p className="c9">
        <span className="c4">
          Company shall use reasonable efforts consistent with prevailing
          industry standards to maintain the Services in a manner which
          minimizes errors and interruptions in the Services and shall perform
          the Implementation Services in a professional and workmanlike manner.
          &nbsp;Services may be temporarily unavailable for scheduled
          maintenance or for unscheduled emergency maintenance, either by
          Company or by third-party providers, or because of other causes beyond
          Company’s reasonable control, but Company shall use reasonable efforts
          to provide advance notice in writing or by e-mail of any scheduled
          service disruption. &nbsp;
        </span>
        <span className="c4">
          HOWEVER, COMPANY DOES NOT WARRANT THAT THE SERVICES WILL BE
          UNINTERRUPTED OR ERROR FREE; NOR DOES IT MAKE ANY WARRANTY AS TO THE
          RESULTS THAT MAY BE OBTAINED FROM USE OF THE SERVICES.
        </span>
        <span className="c0">&nbsp; </span>
        <span className="c4">
          EXCEPT AS EXPRESSLY SET FORTH IN THIS SECTION, THE SERVICES AND
          IMPLEMENTATION SERVICES ARE PROVIDED “AS IS” AND COMPANY DISCLAIMS ALL
          WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED
          WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE AND
          NON-INFRINGEMENT.
        </span>
      </p>
      <p className="c11">
        <span className="c4">&nbsp; </span>
      </p>
      <ol className="c5 lst-kix_list_2-2" start={7}>
        <li className="c12">
          <p style={{ display: `inline` }}>
            <span className="c3">LIMITATION OF LIABILITY</span>
          </p>
        </li>
      </ol>
      <p className="c9">
        <span className="c4">
          NOTWITHSTANDING ANYTHING TO THE CONTRARY, EXCEPT FOR BODILY INJURY OF
          A PERSON, COMPANY AND ITS SUPPLIERS (INCLUDING BUT NOT LIMITED TO ALL
          EQUIPMENT AND TECHNOLOGY SUPPLIERS), OFFICERS, AFFILIATES,
          REPRESENTATIVES, CONTRACTORS AND EMPLOYEES SHALL NOT BE RESPONSIBLE OR
          LIABLE WITH RESPECT TO ANY SUBJECT MATTER OF THIS AGREEMENT OR TERMS
          AND CONDITIONS RELATED THERETO UNDER ANY CONTRACT, NEGLIGENCE, STRICT
          LIABILITY OR OTHER THEORY: (A)&nbsp;FOR ERROR OR INTERRUPTION OF USE
          OR FOR LOSS OR INACCURACY OR CORRUPTION OF DATA OR COST OF PROCUREMENT
          OF SUBSTITUTE GOODS, SERVICES OR TECHNOLOGY OR LOSS OF BUSINESS;
          (B)&nbsp;FOR ANY INDIRECT, EXEMPLARY, INCIDENTAL, SPECIAL OR
          CONSEQUENTIAL DAMAGES; (C)&nbsp;FOR ANY MATTER BEYOND COMPANY’S
          REASONABLE CONTROL; OR (D) FOR ANY AMOUNTS THAT, TOGETHER WITH AMOUNTS
          ASSOCIATED WITH ALL OTHER CLAIMS, EXCEED THE FEES PAID BY CUSTOMER TO
          COMPANY FOR THE SERVICES UNDER THIS AGREEMENT IN THE 12 MONTHS PRIOR
          TO THE ACT THAT GAVE RISE TO THE LIABILITY, IN EACH CASE, WHETHER OR
          NOT COMPANY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.{` `}
        </span>
      </p>
      <ol className="c5 lst-kix_list_2-2" start={8}>
        <li className="c12">
          <p style={{ display: `inline` }}>
            <span className="c3">MISCELLANEOUS</span>
          </p>
        </li>
      </ol>
      <p className="c9">
        <span className="c4">
          If any provision of this Agreement is found to be unenforceable or
          invalid, that provision will be limited or eliminated to the minimum
          extent necessary so that this Agreement will otherwise remain in full
          force and effect and enforceable. &nbsp;This Agreement is not
          assignable, transferable or sublicensable by Customer except with
          Company’s prior written consent. &nbsp;Company may transfer and assign
          any of its rights and obligations under this Agreement without
          consent. &nbsp;This Agreement is the complete and exclusive statement
          of the mutual understanding of the parties and supersedes and cancels
          all previous written and oral agreements, communications and other
          understandings relating to the subject matter of this Agreement, and
          that all waivers and modifications must be in a writing signed by both
          parties, except as otherwise provided herein. &nbsp;No agency,
          partnership, joint venture, or employment is created as a result of
          this Agreement and Customer does not have any authority of any kind to
          bind Company in any respect whatsoever. &nbsp;In any action or
          proceeding to enforce rights under this Agreement, the prevailing
          party will be entitled to recover costs and attorneys’ fees. &nbsp;All
          notices under this Agreement will be in writing and will be deemed to
          have been duly given when received, if personally delivered; when
          receipt is electronically confirmed, if transmitted by facsimile or
          e-mail; the day after it is sent, if sent for next day delivery by
          recognized overnight delivery service; and upon receipt, if sent by
          certified or registered mail, return receipt requested. &nbsp;This
          Agreement shall be governed by the laws of the State of California
          without regard to its conflict of laws provisions. &nbsp;
        </span>
      </p>
      <p className="c21">
        <span className="c4">&nbsp;</span>
      </p>
      <p className="c21 c24">
        <span className="c4" />
      </p>
      <hr style={{ pageBreakBefore: `always`, display: `none` }} />
      <p className="c22">
        <span className="c4" />
      </p>
      <p className="c7">
        <span className="c4">
          <br />
        </span>
      </p>
      <hr style={{ pageBreakBefore: `always`, display: `none` }} />
      <p className="c16">
        <span className="c8 c18">EXHIBIT A</span>
      </p>
      <p className="c16">
        <span className="c3">Service Level Terms</span>
      </p>
      <p className="c9">
        <span className="c4">
          The Services shall be available 99.9%, measured monthly, excluding
          holidays and weekends and scheduled maintenance. &nbsp;If Customer
          requests maintenance during these hours, any uptime or downtime
          calculation will exclude periods affected by such maintenance.
          &nbsp;Further, any downtime resulting from outages of third party
          connections or utilities or other reasons beyond Company’s control
          will also be excluded from any such calculation. Customer&apos;s sole and
          exclusive remedy, and Company&apos;s entire liability, in connection with
          Service availability shall be that for each period of downtime lasting
          longer than one hour, Company will credit Customer 5% of Service fees
          for each period of 30 or more consecutive minutes of downtime;
          provided that no more than one such credit will accrue per day.
          &nbsp;Downtime shall begin to accrue as soon as Customer (with notice
          to Company) recognizes that downtime is taking place, and continues
          until the availability of the Services is restored. &nbsp;In order to
          receive downtime credit, Customer must notify Company in writing
          within 24 hours from the time of downtime, and failure to provide such
          notice will forfeit the right to receive downtime credit. &nbsp;Such
          credits may not be redeemed for cash and shall not be cumulative
          beyond a total of credits for one&nbsp;(1) week of Service Fees in any
          one&nbsp;(1) calendar month in any event. &nbsp;Company will only
          apply a credit to the month in which the incident occurred.
          &nbsp;Company’s blocking of data communications or other Service in
          accordance with its policies shall not be deemed to be a failure of
          Company to provide adequate service levels under this Agreement.
        </span>
      </p>
      <hr style={{ pageBreakBefore: `always`, display: `none` }} />
      <p className="c16">
        <span className="c18 c8">EXHIBIT B</span>
      </p>
      <p className="c16">
        <span className="c3">Support Terms</span>
      </p>
      <p className="c6" id="h.gjdgxs">
        <span>
          Company will provide Technical Support to Customer via electronic mail
          on weekdays during the hours of 9:00 am through 5:00 pm Pacific time,
          with the exclusion of Federal Holidays (“
        </span>
        <span className="c20">Support Hours</span>
        <span className="c4">”).</span>
      </p>
      <p className="c6">
        <span className="c4">
          Company will use commercially reasonable efforts to respond to all
          inquiries within one (1) business day.
        </span>
      </p>
      <p className="c7">
        <span className="c3" />
      </p>
      <div>
        <p className="c15">
          <span className="c10">GDSVF&amp;H\#308630v1</span>
          <span className="c1">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
        </p>
        <p className="c17">
          <span className="c10">
            GDSVF&amp;H\139611.12&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
        </p>
        <p className="c17">
          <span className="c10">LIBC/3968202.1</span>
        </p>
      </div>
    </div>
  )

export default Terms
